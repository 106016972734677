import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'

const LogoWrap = tw(motion.div)`h-auto mx-auto flex items-center justify-center bg-white absolute z-10 top-0 left-0 right-0 px-5 py-3 rounded-b-md transition-all duration-300 ease-in-out`

const initial = {
	y: '-100%',
}
const animate = {
	y: 0,
}
const transition = {
	duration: 0.4,
}

const Logo = ({ className }) => {
	return (
		<AnimatePresence exitBeforeEnter>
			<LogoWrap className={className} initial={initial} animate={animate} exit={initial} transition={transition}>
				<StaticImage className='object-contain' imgClassName='object-contain h-full w-full' src='../../images/logo.png' alt='' loading='eager' placeholder='none' />
			</LogoWrap>
		</AnimatePresence>
	)
}

export default Logo
