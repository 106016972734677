import React from 'react'
import { motion } from 'framer-motion'
import { useStateContext } from '@context/stateContext'

const duration = 0.25

const topGroup = {
	closed: {
		y: 10,
		transition: {
			delay: duration,
			duration,
		},
	},
	open: {
		y: 22,
		transition: {
			delay: 0,
			duration,
		},
	},
}
const centerGroup = {
	closed: {
		opacity: 1,
		y: 22,
		transition: {
			duration,
			delay: duration,
		},
	},
	open: {
		opacity: 0,
		y: 22,
		transition: {
			duration,
			delay: duration,
		},
	},
}
const bottomGroup = {
	closed: {
		y: 34,
		transition: {
			delay: duration,
			duration,
		},
	},
	open: {
		y: 22,
		transition: {
			delay: 0,
			duration,
		},
	},
}
const top = {
	open: {
		rotate: 135,
		transition: {
			delay: duration,
			duration,
		},
	},
	closed: {
		rotate: 0.01,
		transition: {
			delay: 0,
			duration,
		},
	},
}
const bottom = {
	open: {
		rotate: -135,
		transition: {
			delay: duration,
			duration,
		},
	},
	closed: {
		rotate: 0.01,
		transition: {
			delay: 0,
			duration,
		},
	},
}

function NavBurger({ className, onClick }) {
	const { navState } = useStateContext()
	return (
		<div
			className={`${className} absolute top-spacingXs-xs cursor-pointer border-0 ring-0 right-spacingXs-xs filter drop-shadow-burger z-[1000] hover:drop-shadow-burgerHover transition-all duration-300`}
			aria-label='toggle-navigation'
			role='button'
			tabIndex='0'
			onClick={onClick}
			onKeyDown={onClick}
		>
			<motion.svg viewBox='0 0 48 48' width='48' height='48' animate={navState ? 'open' : 'closed'} initial={'closed'}>
				<motion.g variants={topGroup}>
					<motion.rect variants={top} width='100%' height={4} fill='#FFFFFF' />
				</motion.g>
				<motion.g variants={centerGroup}>
					<motion.rect variants={top} width='100%' height={4} fill='#FFFFFF' />
				</motion.g>
				<motion.g variants={bottomGroup}>
					<motion.rect variants={bottom} width='100%' height={4} fill='#FFFFFF' />
				</motion.g>
			</motion.svg>
		</div>
	)
}

export default NavBurger
