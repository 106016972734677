import React from 'react'
import tw from 'twin.macro'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useStateContext } from '@context/stateContext'
import { globalHistory } from '@reach/router'

const Wrapper = tw.div`absolute top-[33px] pl-5 left-0 md:left-[unset] cursor-pointer border-0 ring-0 md:right-spacingM-master  flex items-center`
const SwitchButton = tw.button`text-sm text-white tracking-1 md:tracking-2 hover:drop-shadow-burgerHover transition-all duration-300 filter drop-shadow-burger`
const SwitchSeparator = tw.span`text-white px-1 xs:px-4`

const LangSwitcher = ({ translationSlug = null }) => {
	const { language, languageHandle } = useStateContext()
	const path = globalHistory.location.pathname
	const allCreatedPages = useStaticQuery(graphql`
		{
			allSitePage {
				edges {
					node {
						path
					}
				}
			}
		}
	`)

	const checkIfPageExists = async (pathDestination) => {
		return allCreatedPages.allSitePage.edges.some(({ node }) => node.path === pathDestination)
	}

	const changeLanguage = async (lang, currentPath) => {
		if (language === lang) return
		if (lang === 'de') {
			if (translationSlug) {
				navigate(translationSlug)
				languageHandle(lang)
				return
			}
			const newPath = currentPath.replace('/en', '')
			navigate(newPath)
			languageHandle(lang)
			return
		}
		if (lang === 'en') {
			if (translationSlug) {
				navigate(translationSlug)
				languageHandle(lang)
				return
			}
			const enPath = '/en'
			const newPath = enPath.concat(currentPath)
			const result = await checkIfPageExists(newPath)
			if (!result) return
			navigate(newPath)
			languageHandle(lang)
			return
		}
	}

	React.useEffect(() => {
		if (path.includes('/en/')) {
			languageHandle('en')
		} else {
			languageHandle('de')
		}
	}, [path]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Wrapper>
			<SwitchButton className={`${language === 'de' ? 'font-futuraExtra' : 'font-futuraBook'}`} onClick={() => changeLanguage('de', path)}>
				DE
			</SwitchButton>
			<SwitchSeparator>|</SwitchSeparator>
			<SwitchButton className={`${language === 'en' ? 'font-futuraExtra' : 'font-futuraBook'}`} onClick={() => changeLanguage('en', path)}>
				EN
			</SwitchButton>
		</Wrapper>
	)
}

export default LangSwitcher
