import React from 'react'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { Link } from 'gatsby'

const NavigationWrap = styled(motion.div)(({ navstate }) => [
	tw`absolute pt-spacingM-master top-0 w-full sm:w-[500px] h-screen overflow-x-hidden overflow-y-auto px-12 pb-16 bg-dark z-20`,
	navstate === 'false' && tw``,
	`
	::-webkit-scrollbar {
		display: none;
	}`,
])

const ParentItemsWrap = tw.div`pt-5 md:pt-10`
const ParentLabel = styled(motion(Link))`
	${tw`font-futuraExtra text-4xl sm:text-5xl text-white uppercase tracking-1 md:tracking-2 pb-4 block`}

	&[aria-current] {
		${tw`!text-[#004bcc]`}
	}
`
const DisabledParentLabel = styled(motion.span)`
	${tw`font-futuraExtra text-4xl sm:text-5xl text-white uppercase tracking-1 md:tracking-2 pb-4 block`}

	&[aria-current] {
		${tw`!text-[#004bcc]`}
	}
`
const ChildLabel = styled(motion(Link))`
	${tw`font-futuraBook text-white text-sm tracking-1 md:tracking-2 uppercase block`}

	&[aria-current] {
		${tw`!text-[#005cb3] font-futuraBold`}
	}
`
const ChildExternal = tw(motion.a)`font-futuraBook text-white text-sm tracking-1 md:tracking-2 uppercase block`

function Navigation({ menuItems }) {
	const { navState, language } = useStateContext()

	return (
		menuItems.length > 0 && (
			<NavigationWrap initial='closed' animate={navState ? 'open' : 'closed'} variants={navVariants} navstate={`${navState}`}>
				{menuItems.map((item) => {
					const uriPath = language === 'en' && item.path === '/en/emil-frey-racing/' ? '/en/' : item.path
					return (
						<ParentItemsWrap key={item.id}>
							{!item.path ? (
								<DisabledParentLabel variants={itemsVariants}>{item.label}</DisabledParentLabel>
							) : (
								<ParentLabel
									whileHover={{
										color: '#0054A1',
										scale: 1.03,
										transition: {
											duration: 0.3,
										},
									}}
									to={uriPath}
									variants={itemsVariants}
								>
									{item.label}
								</ParentLabel>
							)}
							{item.childItems.nodes.length > 0 && <ChildItems children={item.childItems.nodes} />}
						</ParentItemsWrap>
					)
				})}
			</NavigationWrap>
		)
	)
}

const ChildItems = ({ children }) => {
	return (
		children?.length > 0 &&
		children.map((child) => {
			if (child.target === '_blank') {
				return (
					<ChildExternal
						whileHover={{
							color: '#0054A1',
							scale: 1.03,
							transition: {
								duration: 0.3,
							},
						}}
						target='_blank'
						href={child.path}
						key={child.id}
						variants={childItemsVariants}
					>
						{child.label}
					</ChildExternal>
				)
			}
			return (
				<ChildLabel
					whileHover={{
						color: '#0054A1',
						scale: 1.03,
						transition: {
							duration: 0.3,
						},
					}}
					to={child.path}
					key={child.id}
					variants={childItemsVariants}
				>
					{child.label}
				</ChildLabel>
			)
		})
	)
}

const navVariants = {
	closed: {
		right: '-100%',
		transition: {
			type: 'tween',
			duration: 0.4,
		},
	},
	open: {
		right: 0,
		transition: {
			type: 'tween',
			bounce: 0,
			duration: 0.4,
			staggerChildren: 0.03,
		},
	},
}
const itemsVariants = {
	closed: {
		x: '100px',
		transition: {
			type: 'tween',
			ease: 'easeIn',
			duration: 0.4,
		},
	},
	open: {
		x: 0,
		transition: {
			type: 'tween',
			ease: [0.25, 0.75, 0.4, 1],
			duration: 0.6,
		},
	},
}
const childItemsVariants = {
	closed: {
		x: '100px',
		transition: {
			type: 'tween',
			ease: 'easeIn',
			duration: 0.4,
		},
	},
	open: {
		x: 0,
		transition: {
			type: 'tween',
			ease: [0.25, 0.75, 0.4, 1],
			duration: 0.6,
		},
	},
}
export default Navigation
